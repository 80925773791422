'use strict';

(function($) {
    $('[data-href]').each(function() {
        var clickableElement = $(this);
        var link = clickableElement.data('href');
        clickableElement.click(function() {
            window.location = link;
        });
    });
})(jQuery);