'use strict';

(function($) {
    var galleryTop = new Swiper('.material-gallery', {});
    var galleryThumbs = new Swiper('.material-thumbs-gallery', {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        keyboardControl: true
    });
    galleryTop.params.control = galleryThumbs;
    galleryThumbs.params.control = galleryTop;
})(jQuery);