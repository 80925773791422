'use strict';

(function($, Swiper){
    // Initialize Hero Header
    $(document).ready(function() {
        var swiperInstance = new Swiper('.hero-slider', {
            pagination: '.swiper-pagination',
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            autoplay: 4000,
            speed: 900
        });
    });

})(window.jQuery, window.Swiper);
