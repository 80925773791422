'use strict';

(function($) {

    function resetFormErrors(form) {
        $('.form-group', form).removeClass('has-error');
    }

    function resetForm(form) {
        $('.form-control', form).val('');
    }

    $('form.js-ajax-form').on('submit', function(event) {
        event.preventDefault();

        var form = $(this);
        var action = form.attr('action');
        var data = form.serialize();

        $('input[type="submit"]').attr('disabled', 'disabled');

        $.ajax({
            type: 'POST',
            url: action,
            data: data,
            dataType: 'json'
        })
            .success(function() {
                resetFormErrors(form);
                resetForm(form);
                form.addClass('has-success');

                setTimeout(function() {
                    form.removeClass('has-success');
                }, 5000);
            })
            .error(function() {
                resetFormErrors(form);

                var errors = request.responseJSON;
                for (var field in errors) {
                    if (errors.hasOwnProperty(field)) {
                        $('input[name="' + field + '"]', form).closest('.form-group').addClass('has-error')
                    }
                }
            })
            .always(function() {
                $('input[type="submit"]').removeAttr('disabled');
            });
    });
})(jQuery);